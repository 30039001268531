<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String,
    },
    input: {
      type: String,
    },
  },
  data: () => ({
    keyboard: null,
    shiftActive: false,
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: [
          '§ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
          '@ q w e r t y u i o p [ ]',
          '{lock} a s d f g h j k l ; \' \\',
          '{shift} ` z x c v b n m , . / {shift}',
          '{space}',
        ],
        shift: [
          '± ! € # $ % ^ & * ( ) _ + {bksp}',
          '@ Q W E R T Y U I O P { }',
          '{lock} A S D F G H J K L : " |',
          '{shift} ~ Z X C V B N M < > ? {shift}',
          '{space}',
        ],
      },
    });
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input);
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button);
      if (button === '{shift}' || button === '{lock}') {
        this.handleShift();
        if (button === '{shift}') {
          this.shiftActive = !this.shiftActive;
        }
      } else if (this.shiftActive) {
        this.keyboard.setOptions({
          layoutName: 'default',
        });
        this.shiftActive = false;
      }
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>

<style>
.simple-keyboard {
  height: 100%;
}

.hg-row {
  height: 18.5%;
}

.hg-rows {
  height: 100%;
}

.hg-button {
  font-family: 'OswaldLight', Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.3vh;
  height: 100% !important;
}

.simple-keyboard {
  background: none;
}
</style>
