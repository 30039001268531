<template>
  <div class="email">
    <img class="banner" src="@/assets/TopBanner.png" alt=""/>
    <div
      class="input-container"
    >
      <div class="prompt">
        <div>
          <img src="@/assets/GetYourGif.png" alt="gyg"/>
        </div>
      </div>
      <div class="input">
        <div class="text">
          You can send this GIF to multiple email addresses by adding a space between each email
        </div>
        <input
          id="emails"
          type="email"
          placeholder="Enter email/s to share"
          inputmode="none"
          autocomplete="off"
          readonly
          v-model="addresses"
          :disabled="$store.state.sendingEmail || $store.state.sentEmail"
        >
        <div class="keyboard-container">
          <SimpleKeyboard
            @onChange="onChange"
            @onKeyPress="onKeyPress"
            :input="addresses"
          />
        </div>
        <div class="buttons-container">
          <div class="button-container">
            <div
              class="button skip-button"
              :class="$store.state.sendingEmail ? 'disabled' : ''"
              @click="$router.push('/end')"
            />
          </div>
          <div class="button-container">
            <div
              class="button send-button"
              :class="(
                $store.state.sendingEmail ||
                $store.state.sentEmail ||
                !validEmails
              ) ? 'disabled' : ''"
              @click="sendEmails"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import EmailValidator from 'email-validator';
import SimpleKeyboard from '@/components/SimpleKeyboard.vue';

export default {
  components: {
    SimpleKeyboard,
  },
  computed: {
    validEmails() {
      const emails = this.addresses.split(/(\s+)/).filter((e) => e.trim().length > 0);

      if (!emails.length) {
        return false;
      }

      for (let i = 0; i < emails.length; i += 1) {
        if (!EmailValidator.validate(emails[i])) {
          return false;
        }
      }

      return true;
    },
  },
  data() {
    return {
      addresses: '',
    };
  },
  methods: {
    finishEmailing() {
      this.$router.push('/end');
    },

    sendEmails() {
      const url = `${process.env.VUE_APP_SERVER_ADDRESS}/api/send-emails/`;
      // Split by whitespace then extract addresses
      const emails = this.addresses.split(/(\s+)/).filter((e) => e.trim().length > 0);

      this.$store.commit('sendingEmail');

      axios.post(
        url,
        {
          addresses: emails,
          event_session_id: this.$store.state.currentBatch.id,
        },
        {
          headers: {
            Authorization: `Token ${this.$store.state.serverToken}`,
          },
        },
      )
        .catch((error) => {
          console.log(error);
          this.$store.commit('sentEmail', {
            success: false,
            error: true,
          });
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('sentEmail', {
              success: true,
              error: null,
            });

            this.finishEmailing();
          } else {
            this.$store.commit('sentEmail', {
              success: false,
              error: true,
            });
          }
        });
    },
    onChange(input) {
      this.addresses = input;
    },
    onKeyPress() {

    },
    onInputChange(input) {
      this.addresses = input.target.value;
    },
  },
};
</script>

<style scoped>
.email {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.prompt {
  align-items: flex-end;
  display: flex;
  height: 47%;
}

.prompt img {
  margin-bottom: 1%;
  width: 80%;
}

.input {
  height: 53%;
  background: #fff;
}

.text {
  align-items: center;
  display: flex;
  font-family: 'OswaldLight';
  font-size: 1.49vh;
  height: 4vh;
  justify-content: center;
  line-height: 100%;
  text-transform: uppercase;
}

input {
  height: 5vh;
  width: 79%;
  background: #fafafa;
  text-align: center;
  text-transform: uppercase;
  font-family: 'OslwaldVariable';
  border-color: #e3e3e3;
  border-style: solid;
  border-radius: .5vh;
  font-size: 2.5vh;
}

input::placeholder {
  color: #e3e3e3;
  opacity: 1;
}

.keyboard-container {
  height: 17vh;
  margin: auto;
  width: 79%;
  background: #fafafa;
  border-radius: .5vh;
  border-color: #e3e3e3;
  border-style: solid;
  margin-top: 0.3vh;
  border-width: 2px;
}

.send-button {
  background-image: url('@/assets/Send_Button.png');
  right: 10%;
}

.skip-button {
  background-image: url('@/assets/Skip_Button.png');
  left: 10%;
}

.disabled {
  opacity: .5;
}

.input-container {
  height: 100vh;
}

.confirmation-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.confirmation-container img {
  width: 82%;
}
</style>
