<template>
  <div class="home">
    <img class="banner" src="@/assets/TopBanner.png" alt=""/>
    <div class="prompt-container">
      <div v-if="!waitingForBatch">
        <img src="@/assets/PressTheGlobal.png" alt=""/>
      </div>
      <div v-else>
        <img src="@/assets/SelfieCameraInAction.png" alt=""/>
      </div>
    </div>
    <div class="button-container">
      <div
        class="trigger-button button"
        :class="waitingForBatch ? 'disabled': ''"
        @click="triggerCamera"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomeView',
  data() {
    return {
      batchInQueue: false,
      waitingForBatch: false,
    };
  },
  methods: {
    triggerCamera() {
      axios.get(
        `${process.env.VUE_APP_SERVER_ADDRESS}/api/trigger-selfie/`,
        {
          headers: {
            Authorization: `Token ${this.$store.state.serverToken}`,
          },
        },
      ).then((response) => {
        const { data } = response;
        if (data.connected.length) {
          this.waitingForBatch = true;
        }
      });
    },
    startNewSession() {
      const savedToken = window.localStorage.getItem('serverToken');

      if (savedToken) {
        this.$store.commit('loadNewBatch');
        this.$router.push('/preview');
      }
    },
  },
  mounted() {
    if (this.$store.state.batchQueue.length || this.$store.state.currentBatch) {
      this.startNewSession();
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'setInitialBatches' && this.$store.state.currentBatch) {
        this.startNewSession();
      }

      if (mutation.type === 'addBatch' && !this.$store.state.currentBatch) {
        this.startNewSession();
      }
    });
  },
};
</script>
<style scoped>
.home {
  height: 100%;
  width: 100%;
}

.banner {
  width: 100%;
}

.prompt-container {
  align-items: center;
  display: flex;
  height: 34vh;
  margin-top: 20vh;
}

.prompt-container img {
  width: 56%;
}

.button-container {
  position: absolute;
  bottom: 6.5vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  width: 14vh;
  height: 14vh;
  background-repeat: no-repeat;
  background-size: contain;
}

.trigger-button {
  background-image: url('@/assets/Wheel_Button.png');
}

.disabled {
  opacity: .5;
}
</style>
