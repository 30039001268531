/* eslint-disable vuejs-accessibility/label-has-for */
<template>
  <div class="login-container">
    <div class="login">
      <h2>Server Login</h2>
      <label for="user">
        Username
        <input id="user" v-model="username">
      </label>
      <br>
      <label for="pass">
        Password
        <input id="pass" type="password" v-model="password">
      </label>
      <br>
      <div v-if="errorMessage">{{ errorMessage }}</div>
      <br>
      <br>
      <button @click="login()">Login</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    login() {
      const url = `${process.env.VUE_APP_SERVER_ADDRESS}/api-token-auth/`;

      axios.post(
        url,
        {
          username: this.username,
          password: this.password,
        },
      )
        .catch((error) => {
          this.errorMessage = error;
        })
        .then((response) => {
          if (response) {
            const { token } = response.data;
            window.localStorage.setItem('serverToken', token);
            this.$store.commit('setServerToken', token);
            this.$router.push('/');
          }
        });
    },
  },
};
</script>

<style>

</style>
