<template>
  <div id="app">
    <div class="video-container">
      <video
        v-if="$store.state.currentBatch"
        ref="video"
        class="video-player"
        autoplay
        muted
        loop
        :src="$store.state.currentBatch.video_file"
      />
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      socket: null,
      connected: false,
    };
  },
  methods: {
    setupSocket() {
      this.gallerySocket = new WebSocket(
        `${process.env.VUE_APP_SOCKET_ADDRESS}/ws/sharing/SS/`,
      );

      this.gallerySocket.onopen = (e) => {
        console.log(e);

        this.connected = true;
      };

      this.gallerySocket.onclose = (e) => {
        console.log(e);

        this.connected = false;

        console.log('Waiting 5s to reconnect');

        setTimeout(() => {
          this.setupSocket();
        }, 5000);
      };

      this.gallerySocket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (data.type === 'send_initial_sessions') {
          this.$store.commit('setInitialBatches', data.sessions);
        }

        if (data.type === 'send_session') {
          this.$store.commit('addBatch', {
            id: data.id,
            video_file: data.video_file,
            print_file: data.print_file,
          });
        }
      };
    },
  },
  mounted() {
    const savedToken = window.localStorage.getItem('serverToken');

    if (!savedToken) {
      this.$router.push('/login');
    } else {
      this.$store.commit('setServerToken', savedToken);
    }

    this.setupSocket();
  },
};
</script>

<style>
@font-face {
  font-family: "OswaldLight";
  src:  url("~@/assets/fonts/Oswald-Light.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "OslwaldVariable";
  src:  url("~@/assets/fonts/Oswald-Variable.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src:  url("~@/assets/fonts/Roboto-Bold.ttf");
  font-weight: normal;
}

body {
  background: #fff;
  margin: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  height: 100vh;
  margin: auto;
  width: 100vw;
  position: relative;
  max-width: calc(100vh * 1600/2407);
}

.video-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  overflow: hidden;
}

.video-container video {
  width: 100%;
}
</style>
