import Vue from 'vue';
import VueRouter from 'vue-router';

import EndSession from '@/views/EndSession.vue';
import HomeView from '@/views/HomeView.vue';
import LogIn from '@/views/LogIn.vue';
import SendEmail from '@/views/SendEmail.vue';
import SessionPreview from '@/views/SessionPreview.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: LogIn,
  },
  {
    path: '/end',
    name: 'end',
    component: EndSession,
  },
  {
    path: '/email',
    name: 'email',
    component: SendEmail,
  },
  {
    path: '/preview',
    name: 'preview',
    component: SessionPreview,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
