<template>
 <div class="end">
    <img src="@/assets/end.png" alt="end">
  </div>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    finishSession() {
      const url = `
        ${process.env.VUE_APP_SERVER_ADDRESS}/api/sessions/event-sessions/${this.$store.state.currentBatch.id}/
      `;

      axios.patch(
        url,
        {
          complete: true,
        },
        {
          headers: {
            Authorization: `Token ${this.$store.state.serverToken}`,
          },
        },
      );

      this.$store.commit('clearCurrentBatch');

      setTimeout(() => {
        this.$router.push('/');
      }, 5000);
    },
  },
  mounted() {
    this.finishSession();
  },
  updated() {
    this.finishSession();
  },
};
</script>

<style>
.end {
  height: 100%;
}

.end img {
  width: 100%;
  height: 100%;
}
</style>
