import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    serverToken: '',
    batchQueue: [],
    currentBatch: null,
    initialBatchLoaded: false,
    emailAddresses: '',
    sendingEmail: false,
    sentEmail: false,
    sendingEmailError: false,
  },
  getters: {
  },
  mutations: {
    setServerToken(state, token) {
      state.serverToken = token;
    },
    setInitialBatches(state, batches) {
      if (batches.length) {
        state.currentBatch = batches.shift();
        if (batches.length) {
          state.batchQueue = batches;
        }
      }
      state.initialBatchLoaded = true;
    },
    addBatch(state, batch) {
      const { batchQueue } = state;
      batchQueue.push(batch);
      state.batchQueue = batchQueue;
    },
    loadNewBatch(state) {
      const { batchQueue } = state;
      if (batchQueue.length) {
        [state.currentBatch] = batchQueue;
        batchQueue.shift();
        state.batchQueue = batchQueue;
        state.emailAddresses = '';
        state.sendingEmail = false;
        state.sentEmail = false;
        state.sendingEmailError = false;
      }
    },
    clearCurrentBatch(state) {
      state.currentBatch = null;
    },
    setEmailAddresses(state, addresses) {
      state.emailAddresses = addresses;
    },
    sendingEmail(state) {
      state.sendingEmail = true;
    },
    sentEmail(state, payload) {
      state.sendingEmail = false;
      state.sentEmail = payload.success;
      state.sendingEmailError = payload.error;
    },
  },
  actions: {
  },
  modules: {
  },
});
