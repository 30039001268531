<template>
  <div class="preview">
    <div class="buttons-container" v-if="!showCancelPrompt">
      <div class="button-container">
        <div
          class="button email-button"
          @click="continueSession"
        />
      </div>
      <div class="button-container">
        <div
          class="button cancel-button"
          @click="cancel"
        />
      </div>
    </div>
    <div class="cancel-container" v-else>
      <div class="prompt-container">
        <img src="@/assets/AreYouSure.png" alt="ays"/>
      </div>
      <div class="buttons-container">
        <div class="button-container">
          <div
            class="button yes-button"
            @click="endSession"
          />
        </div>
        <div class="button-container">
          <div
            class="button no-button"
            @click="showCancelPrompt = !showCancelPrompt;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCancelPrompt: false,
    };
  },
  methods: {
    cancel() {
      this.showCancelPrompt = !this.showCancelPrompt;
    },
    continueSession() {
      this.$router.push('/email');
    },
    endSession() {
      this.showCancelPrompt = !this.showCancelPrompt;
      this.$router.push('/end');
    },
  },
};
</script>

<style>
.preview {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.button {
  background-repeat: no-repeat;
  background-size: contain;
  height: 13vh;
  width: 13vh;
  position: absolute;
  bottom: 5vh;
}

.buttons-container {
  display: flex;
  width: 100%;
}

.cancel-container {
  width: 100%;
}

.button-container {
  width: 50%;
}

.cancel-button {
  background-image: url('@/assets/Cancel_Button.png');
  right: 9%;
}

.email-button {
  background-image: url('@/assets/Share_Button.png');
  left: 9%;
}

.yes-button {
  background-image: url('@/assets/Yes_Button.png');
  left: 9%;
}

.no-button {
  background-image: url('@/assets/No_Button.png');
  right: 9%;
}

.prompt-container {
  width: 100%;
  position: relative;
  height: 35vh;
}

.prompt-container img {
  width: 71%;
}
</style>
